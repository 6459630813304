import React from 'react'
import { connect } from 'react-redux'

import './styles.scss'
import { HomeDefaultContainer, ConditionalRedirect } from '../../components'

const HomePage = ({ isLogged }) => (
  <>
    <HomeDefaultContainer />
    <ConditionalRedirect condition={isLogged} to="/scan" />
  </>
)

const MapStateToProps = state => ({
  isLogged: state.user.isLogged,
})

export default connect(MapStateToProps)(HomePage)
