import React from 'react'

import TitleBlock from '../title-block'
import FooterBlock from '../footer-block'
import Button from '../nav-button'

export default () => {
  const topInfo = {
    title: 'Bienvenue sur plume !',
    emoji: null,
    text: 'Renseignez les champs ci-dessous pour utiliser les services Plume',
  }

  return (
    <div className="home-container">
      <div className="home-top">
        <div className="home-logo">
          <img src="/assets/images/logo.png" alt="Plume" />
        </div>
        <TitleBlock
          className="title-white title-margin-top"
          title={topInfo.title}
          emoji={topInfo.emoji}
          text={topInfo.text}
        />
      </div>
      <FooterBlock className="footer-black">
        <Button className="" to="/login" label="Se connecter" />
      </FooterBlock>
    </div>
  )
}
