import React from 'react'
import { connect } from 'react-redux'
import { ActionButton, NavButton, NavHeader, FooterBlock, Input } from '../../components'
import { forgotPassword } from '../../api'
import { openModal } from '../../actions/modal'

import './styles.scss'

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      error: '',
      success: false,
    }

    this.onChangeValue = this.onChangeValue.bind(this)
  }

  onChangeValue(key) {
    return e => {
      this.setState({ [key]: e.target.value, error: '' })
    }
  }

  onSubmit(e) {
    e.preventDefault()
    this.formSubmit()
  }

  formSubmit() {
    const { email } = this.state
    forgotPassword(
      { user: { email } },
      () => this.setState({ success: true }),
      err => {
        this.setState({ error: err })
        this.props.openNotif('Erreur', 'Une erreur est survenue: ' + err, 'error', () => null)
      },
    )
  }

  renderForm() {
    const { email, error } = this.state

    return (
      <>
        <div className="account-top">
          <NavHeader prev="/" pageTitle="Mot de passe oublié" help="" />
          <div className="reset-text">
            Renseignez votre adresse e-mail, afin de pouvoir modifier votre mot de passe.
          </div>
          <form className="account-form" onSubmit={(e) => this.onSubmit(e)}>
            <Input
              value={email}
              className=""
              label="Adresse e-mail"
              type="text"
              onChange={this.onChangeValue('email')}
            />
          </form>
          <div className="account-form">{error && `Une erreur est survenue : ${error}`}</div>
        </div>
        <FooterBlock className="footer-gray">
          {email ? (
            <ActionButton
              className="button-black"
              onClick={() => this.formSubmit()}
              label="Envoyer"
              icon=""
            />
          ) : (
            <ActionButton
              className="button-disabled"
              onClick={() => null}
              label="Envoyer"
              icon=""
            />
          )}
        </FooterBlock>
      </>
    )
  }

  renderSuccess() {
    return (
      <div className="account-top">
        <NavHeader prev="/" pageTitle="Mot de passe oublié" help="" />
        <div className="reset-text">
          Un email vous a été envoyé pour redéfinir votre mot de passe.
        </div>
        <FooterBlock className="footer-gray">
          <NavButton
            className="button-black"
            to="/login"
            label="Retour"
            icon="/assets/images/arrow-right-blue.png"
          />
        </FooterBlock>
      </div>
    )
  }

  render() {
    const { success } = this.state

    return (
      <div className="account-container">{success ? this.renderSuccess() : this.renderForm()}</div>
    )
  }
}

const MapDispatchToProps = dispatch => ({
  openNotif: (title, message, type, onClose) => dispatch(openModal(title, message, type, onClose)),
})

export default connect(null, MapDispatchToProps)(ForgotPassword)
