import React from 'react'
import {
  ActionButton,
  NavHeader,
  FooterBlock,
  Input,
  RegisterConfirmationModal,
  TitleBlock,
  ConditionalRedirect,
} from '../../components'

export default class AskAccount extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      registrationConfirmation: false,
      shouldRedirect: false,
    }
    this.onChangeValue = this.onChangeValue.bind(this)
  }

  onChangeValue(key) {
    return e => {
      this.setState({ [key]: e.target.value })
    }
  }

  formSubmit() {
    console.log('envoyer')
    this.setState({ registrationConfirmation: true })
  }

  render() {
    const { shouldRedirect, registrationConfirmation } = this.state
    const topInfo = {
      title: 'Bonjour 👋',
      emoji: '',
      text: 'Renseignez les champs ci-dessous pour utiliser les services Plume.',
    }

    return (
      <div className="account-container">
        <div className="account-top">
          <NavHeader prev="/" pageTitle="Créer un compte" help="" />
          <TitleBlock
            className="title-margin-top"
            title={topInfo.title}
            emoji={topInfo.emoji}
            text={topInfo.text}
          />
          <div className="account-form">
            <Input
              className=""
              label="Adresse e-mail"
              type="text"
              onChange={this.onChangeValue('email')}
            />
          </div>
        </div>
        <FooterBlock className="footer-gray">
          <ActionButton
            className="button-black icon-reverse"
            onClick={() => this.formSubmit()}
            label="Envoyer ma demande"
            icon="/assets/images/send-blue.png"
          />
        </FooterBlock>
        <RegisterConfirmationModal
          isOpened={registrationConfirmation}
          onClose={() => this.setState({ shouldRedirect: true })}
        />
        <ConditionalRedirect condition={shouldRedirect} to="/" />
      </div>
    )
  }
}
