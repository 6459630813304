import React from 'react'
import { connect } from 'react-redux'
import QrReader from 'react-qr-reader'
import { get, find } from 'lodash'
import classnames from 'classnames'
import {
  NavHeader,
  FooterBlock,
  Input,
  UnlockingConfirmationModal,
  ConditionalRedirect,
} from '../../components'
import { createRental } from '../../api'

import './styles.scss'
import { openModal, closeModal } from '../../actions/modal'

class Scan extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      editing: false,
      qrValue: '',
      rackName: '',
      loading: false,
      textfield: '',
    }
    this.handleScan = this.handleScan.bind(this)
    this.onChangeValue = this.onChangeValue.bind(this)
  }

  onChangeValue(key) {
    return e => {
      this.setState({ [key]: e.target.value })
    }
  }

  handleScan(data) {
    const { racks } = this.props
    if (data) {
      const rack = find(racks, r => r.uid == data)
      console.log('data', data)
      if (rack) {
        console.log(rack, rack)
        this.setState({
          qrValue: data,
          rackName: rack.name,
        })
      } else {
        this.handleQRError()
      }
    }
  }

  handleQRError() {
    this.props.openNotif('Erreur', 'QR code non valide', 'error', () => null)
  }

  handleUnlockRequestError() {
    this.setState({ qrValue: '', rackName: '' })
    this.props.openNotif('Erreur', 'Erreur lors du dévérouillage de la borne', 'error', () => null)
  }

  createRental(onSuccess, onFail) {
    const { qrValue } = this.state

    createRental(
      qrValue,
      () => onSuccess(),
      () => {
        onFail()
        this.handleUnlockRequestError()},
    )
  }

  render() {
    const { editing, loading, qrValue, textfield, rackName } = this.state
    const { isRenting, racks } = this.props
    console.log('racks', racks)

    return (
      <div className="scan-container">
        <NavHeader pageTitle="Scanner un QR Code" help="/how-to" />
        <div className="scan-content">
          {loading && <img src="/assets/images/loading.png" alt="loading" />}
          <QrReader
            onError={() => this.handleQRError()}
            onScan={this.handleScan}
            style={{
              width: '100%',
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}
          />
        </div>
        <div className={classnames('scan-footer', { editing })}>
          <FooterBlock className="footer-black">
            <div className="">
              <div className="scan-hint">
                Scanner le QR code ou entrer manuellement le numéro de la borne :
              </div>
              <Input
                className="scan-footer-input"
                placeholder="Numéro de la borne"
                type="text"
                onChange={this.onChangeValue('textfield')}
                onFocus={() => this.setState({ editing: true })}
                onBlur={() => this.setState({ editing: false })}
                onKeyPress={event => {
                  if (event.key === 'Enter') {
                    const rack = find(racks, r => r.name == textfield)
                    if (rack) {
                      this.setState({ rackName: textfield, qrValue: rack.uid })
                    } else {
                      this.handleQRError()
                    }
                  }
                }}
              />
            </div>
          </FooterBlock>
        </div>
        <UnlockingConfirmationModal
          isOpened={qrValue !== '' || rackName !== ''}
          onClose={() => this.setState({ qrValue: '', rackName: '' })}
          onSubmit={(onSuccess, onFail) => this.createRental(onSuccess, onFail)}
          rackNumber={rackName}
        />
        <ConditionalRedirect condition={isRenting} to="/rental" />
      </div>
    )
  }
}

const MapStateToProps = ({ user, racks: { racks } }) => ({
  isRenting: get(user, 'lastRental', false) && !get(user, 'lastRental.endedAt', true),
  racks: racks,
})

const MapDispatchToProps = dispatch => ({
  openNotif: (title, message, type, onClose) => dispatch(openModal(title, message, type, onClose)),
  closeNotif: () => dispatch(closeModal()),
})

export default connect(MapStateToProps, MapDispatchToProps)(Scan)
