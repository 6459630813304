import React from 'react'
import { NavButton, NavHeader, FooterBlock, TitleBlock, HowTo } from '../../components'

export default () => {
  const slides = [
    {
      img: '/assets/images/howTo_1.png',
      text: "Avec l'app PLUME, ",
      bold: 'flashez le QR Code sur les bornes, ',
      text2: 'ou entrez manuellement le numéro de la borne.',
    },
    {
      img: '/assets/images/howTo_2.png',
      text: 'Débloquez la trottinette dès que le ',
      bold: 'voyant lumineux de la borne clignote.',
      text2: '',
    },
    {
      img: '/assets/images/howTo_3.png',
      text: 'Dépliez la trottinette. ',
      bold: 'Vous avez entendu un clic ! ',
      text2: "C'est bon, elle est prête à être utilisée.",
    },
    {
      title: 'Les bonnes pratiques de la trottinette',
      text: (
        <ol>
          <li>Avant de démarrer, prenez le temps d'apprendre les bases de la pratique.</li>
          <li>
            Respectez toutes les règles : code de la route, code du trottoir, feux, stop, priorités
            etc.
          </li>
          <li>
            Donnez toujours la priorité aux piétons. Et notamment aux usagers les plus vulnérables.{' '}
          </li>
          <li>
            Anticipez toujours vos trajectoires, vos freinages et indiquez tout changement de
            direction.
          </li>
          <li>Prenez garde à vous et aux autres. N'adoptez pas de comportement à risque.</li>
        </ol>
      ),
    },
  ]

  return (
    <div className="account-container">
      <div className="account-top">
        <NavHeader prev="/scan" pageTitle="Aide" help="" />
        <TitleBlock
          className="title-margin-top--small"
          title="Comment prendre une trottinette ?"
          emoji=""
          text=""
        />
        <HowTo slides={slides} />
      </div>
      <FooterBlock className="footer-gray">
        <NavButton
          className="button-black icon-reverse"
          to="/scan"
          label="Scanner un QR code"
          icon="/assets/images/scan-blue.png"
        />
      </FooterBlock>
    </div>
  )
}
