import React from 'react'
import Menu from '../menu'
import { Link } from 'react-router-dom'

import './styles.scss'
import { connect } from 'react-redux'

class NavHeader extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpened: false,
    }
  }
  render() {
    const { prev, pageTitle, isLogged, isRenting } = this.props

    return (
      <>
        <div className="nav-header">
          {prev ? (
            <Link to={prev}>
              <img
                src="/assets/images/arrow-left-dark.png"
                className="nav-header-back"
                alt="Retour"
              />
            </Link>
          ) : (
            <div />
          )}
          <div className="nav-header-title">{pageTitle}</div>
          {!!isLogged && (
            <button
              className="nav-header-menu-btn"
              onClick={() => this.setState({ isOpened: true })}
              type="button"
            >
              <img src="/assets/images/menu.png" className="nav-header-help" alt="Aide" />
            </button>
          )}
          {!isLogged && <div className="nav-header-help" />}
        </div>
        <Menu
          isOpened={this.state.isOpened}
          onClose={() => this.setState({ isOpened: false })}
          isRenting={isRenting}
        />
      </>
    )
  }
}

const MapStateToProps = ({ user }) => ({
  isLogged: user.isLogged,
})

export default connect(MapStateToProps)(NavHeader)
