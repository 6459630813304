import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { get } from 'lodash'
import {
  NavHeader,
  FooterBlock,
  ActionButton,
  ConditionalRedirect,
  HowToEndModal,
  Modal,
} from '../../components'
import { openModal, closeModal } from '../../actions/modal'

import './styles.scss'

class Rental extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      started: false,
      helpOpened: false,
    }

    setTimeout(() => {
      this.setState({ started: true })
    }, 2000)
  }

  handleLockError() {
    this.props.openNotif(
      'Erreur',
      'Une erreur est survenue lors du vérouillage de la borne',
      'error',
      () => null,
    )
  }

  handleLockSuccess() {
    this.props.openNotif('Confirmation', 'Votre trotinette a bien été rendue', 'success', () =>
      //get back to scan page
      console.log('TODO: redirect'),
    )
  }

  render() {
    const { started, helpOpened } = this.state
    const { scootNumber, isRenting, rentalStartDate } = this.props
    const then = moment(rentalStartDate)
    const now = moment()
    const timeDif = moment.utc(
      moment(now, 'DD/MM/YYYY HH:mm:ss').diff(moment(then, 'DD/MM/YYYY HH:mm:ss')),
    )
    let duration = ''
    //if (timeDif.days() > 0) {
    //  duration = timeDif.format('D [jour(s)] HH:mm')
    //} else {
      duration = timeDif.format('HH:mm')
    //}

    return (
      <div className="rental-container">
        <ConditionalRedirect condition={!isRenting} to="/" />
        <NavHeader pageTitle="Location en cours" isRenting={true} />
        {timeDif.isValid() && (
          <div className="rental-info">
            <div className="rental-title">Temps de location</div>
            <div className="rental-timer">{duration}</div>
          </div>
        )}
        <Modal className="no-scroll" isOpened transparent onClose={() => null}>
          <div></div>
          <div className="rental-scooter-block">
            <div className="rack">Trottinette</div>
            <div className="rack-number">
              n°
              {scootNumber}
            </div>
            <img className="scooter" src="/assets/images/scooter.png" />
          </div>
          <FooterBlock className="footer-white">
            <ActionButton
              className={`button-red ${started ? '' : 'button-disabled'}`}
              label="Comment terminer une location ?"
              onClick={() => this.setState({ helpOpened: true })}
            />
          </FooterBlock>
        </Modal>
        <HowToEndModal isOpened={helpOpened} onClose={() => this.setState({ helpOpened: false })} />
      </div>
    )
  }
}

const MapStateToProps = ({ user }) => ({
  scootNumber: get(user, 'lastRental.scooterBytesRef'),
  isRenting: get(user, 'lastRental', false) && !get(user, 'lastRental.endedAt', true),
  rentalStartDate: get(user, 'lastRental.startedAt'),
})

const MapDispatchToProps = dispatch => ({
  openNotif: (title, message, type, onClose) => dispatch(openModal(title, message, type, onClose)),
  closeNotif: () => dispatch(closeModal()),
})

export default connect(MapStateToProps, MapDispatchToProps)(Rental)
