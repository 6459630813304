import React from 'react'

import './styles.scss'

export default ({ className = '', isOpened, onClose, transparent = false, children }) =>
  isOpened && (
    <div className={`modal-container ${className}`}>
      {!transparent && (
        <button className="modal-background" onClick={onClose} type="button">
          &nbsp;
        </button>
      )}
      <div className="modal-content">{children}</div>
    </div>
  )
