import React from 'react'
import { connect } from 'react-redux'

import { Redirect } from 'react-router-dom'
import { logoutUser } from '../../actions'

class Logout extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const { logout } = this.props

    logout()
  }

  render() {
    return <Redirect to={'/login'} />
  }
}

const MapStateToProps = ({ user: { error, isLogged } }) => ({
  error,
  isLogged,
})

const MapDispatchToProps = dispatch => ({
  logout: () => dispatch(logoutUser()),
})

export default connect(MapStateToProps, MapDispatchToProps)(Logout)
