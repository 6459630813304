import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { PersistGate } from 'redux-persist/integration/react'
import { get } from 'lodash'

import { AppRouter } from './components'
import rootReducer from './reducers'
import sagaMiddleware, { initSagas } from './sagas'
import * as serviceWorker from './serviceWorker'
import { refreshSession } from './actions'

import Honeybadger from 'honeybadger-js'
import ErrorBoundary from '@honeybadger-io/react'

import './App.scss'
import './Button.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import NotifModal from './components/notif-modal'

//serviceWorker.register();

const honeybadgerConfig = {
  apiKey: process.env.REACT_APP_HONEYBADGER_API_KEY,
  environment: process.env.REACT_APP_HONEYBADGER_ENV,
}

const honeybadger = Honeybadger.configure(honeybadgerConfig)

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(
  persistedReducer,
  applyMiddleware(sagaMiddleware),
  // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
)

const persistor = persistStore(store, undefined, () => {
  const user = get(store.getState(), 'user', {})
  const path = window.location.pathname

  if (user.isLogged) {
    store.dispatch(refreshSession())
  } else if (
    !path.match('^/login') &&
    !path.match('^/create-account') &&
    !path.match('^/reset-password') &&
    !path.match('^/forgot-password')
  ) {
    console.log('Unauthorized access')
    window.location = '/login'
  }
})

initSagas(sagaMiddleware)

const root = document.getElementById('root')

const App = () => (
  <ErrorBoundary honeybadger={honeybadger}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <div className="App">
          <header className="App-header">
            <AppRouter />
            <NotifModal />
          </header>
        </div>
      </PersistGate>
    </Provider>
  </ErrorBoundary>
)

ReactDOM.render(<App />, root)
