import { get } from 'lodash'
import { call, takeLeading, put } from 'redux-saga/effects'
import { me as refreshSessionService, login as apiLogin, getRacks as refreshRacks } from '../api'

import {
  REFRESH_SESSION,
  REFRESH_SESSION_SUCCESS,
  REFRESH_SESSION_ERROR,
  REFRESH_RACKS_SUCCESS,
  REFRESH_RACKS_ERROR,
  LOGIN_USER,
  LOGIN_FAILED,
  LOGIN_SUCCEEDED,
  LOGOUT_SUCCEEDED,
  LOGOUT_USER,
} from '../constants'
import api from '../api/api'

const formattedSession = payload => {
  return {
    ...payload.user,
    lastRental: get(payload, 'membership.lastRental'),
    membership: {
      role: get(payload, 'membership.role'),
      organizationId: get(payload, 'membership.organization.id'),
      organizationName: get(payload, 'membership.organization.name'),
    },
  }
}

function* submitLogin() {
  try {
    yield put({
      type: 'LOGIN_SUBMIT_SUCCESS',
    })
  } catch (e) {
    yield put({ type: 'LOGIN_SUBMIT_FAILED', message: e })
  }
}

function* submitRegister(action) {
  try {
    yield put({ type: 'REGISTER_SUCCESS', payload: action.payload })
  } catch (e) {
    yield put({ type: 'REGISTER_FAILED', message: e })
  }
}

function* logout() {
  try {
    console.log('Logging out')
    yield put({ type: LOGOUT_SUCCEEDED })
    api.clearCookie()
    window.location = '/login'
  } catch (e) {
    yield put({ type: 'LOGOUT_FAILED' })
  }
}

function* login({ payload }) {
  try {
    const { session } = yield call(() => apiLogin(payload))

    console.log('payload login', { session })

    api.setCookie({ session })

    const formattedData = formattedSession(session)

    console.log(formattedData)

    yield put({
      type: LOGIN_SUCCEEDED,
      payload: {
        //...session.user,
        ...formattedData,
        token: session.token,
      },
    })
  } catch (e) {
    yield put({ type: LOGIN_FAILED })
    api.clearCookie()
  }
}

function* refreshSession() {
  try {
    const { me } = yield call(refreshSessionService)
    console.log(me)

    const formattedData = formattedSession(me)

    console.log(formattedData)

    yield put({ type: REFRESH_SESSION_SUCCESS, payload: formattedData })
  } catch (error) {
    yield put({ type: REFRESH_SESSION_ERROR, error })

    window.location = '/login'
  }

  try {
    const { racks } = yield call(refreshRacks)
    yield put({ type: REFRESH_RACKS_SUCCESS, racks })
  } catch (error) {
    yield put({ type: REFRESH_RACKS_ERROR, error })
  }
}

function* authSaga() {
  yield takeLeading('LOGIN_SUBMIT_REQUESTED', submitLogin)
  yield takeLeading('REGISTER_REQUESTED', submitRegister)
  yield takeLeading(LOGOUT_USER, logout)
  yield takeLeading(REFRESH_SESSION, refreshSession)
  yield takeLeading(LOGIN_USER, login)
}

export default authSaga
