import { combineReducers } from 'redux'

import user from './user'
import rental from './rental'
import racks from './racks'
import modal from './modal'

const reducers = combineReducers({
  user,
  rental,
  racks,
  modal,
})

export default reducers
