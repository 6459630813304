import React from 'react'
import Slider from 'react-slick'

const renderSlide = data => (
  <div className="howto-slide">
    {data.title && <h2>{data.title}</h2>}
    {data.img && <img src={data.img} alt="" />}
    <div className="howto-text">
      {data.text}
      {data.bold && <strong>{data.bold}</strong>}
      {data.text2}
    </div>
  </div>
)

export default ({ slides }) => {
  const settings = {
    dots: true,
    infinite: false,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dotsClass: 'howto-pagging', // slick-dots'
  }

  return (
    <div className="howto-slider">
      <Slider {...settings}>{slides.map(slide => renderSlide(slide))}</Slider>
    </div>
  )
}
