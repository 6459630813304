import api from './api'

export const login = user => api.post('/auth/identity/callback', { user })

export const me = () => api.get2('/me')

export const createPassword = (data, onSuccess, onFail) => {
  api.put(
    `/invitation?invitation_token=${data.token}`,
    {
      user: {
        email: data.email,
        password: data.password,
        password_confirmation: data.passwordConfirm,
      },
    },
    () => onSuccess(),
    err => {
      console.error('Failed to confirm account')
      console.error(err)
      onFail(err)
    },
  )
}

export const forgotPassword = (data, onSuccess, onFail) => {
  api.post('/request-reset-password', data, onSuccess, onFail)
}

export const resetPassword = (data, onSuccess, onFail) => {
  api.put('/password', data, onSuccess, onFail)
}

export const logout = action => {
  api.clearCookie()
  action()
}
