import React from 'react'
import FooterBlock from '../footer-block'
import Button from '../action-button'
import Modal from '../modal'
import TitleBlock from '../title-block'

export default ({ isOpened, onClose }) => {
  const topInfo = {
    title: 'Demande enregistrée',
    emoji: '/assets/images/404-tmp.jpg',
    text: 'Merci!',
  }

  return (
    <Modal isOpened={isOpened}>
      <TitleBlock className="" title={topInfo.title} emoji={topInfo.emoji} text={topInfo.text} />
      <FooterBlock className="footer-white">
        <Button
          onClick={() => onClose()}
          className="button-black"
          label="Continuer"
          icon="/assets/images/arrow-right-blue.png"
        />
      </FooterBlock>
    </Modal>
  )
}
