import React from 'react'
import FooterBlock from '../footer-block'
import Button from '../action-button'
import Modal from '../modal'
import { get } from 'lodash'

import './styles.scss'
import { connect } from 'react-redux'
import { closeModal } from '../../actions/modal'

class NotifModal extends React.Component {
  handleClose() {
    this.props.onClose()
    this.props.closeModal()
  }

  render() {
    const { isOpened, onClose, title, message, type } = this.props
    const icon = type === 'error' ? 'error.png' : 'done.png'

    return (
      <Modal isOpened={isOpened} onClose={() => onClose()} className={`${type}-modal`}>
        <div className="notif-title">{title}</div>
        <div className="notif-picto">
          <img src={`/assets/images/${icon}`} alt={title} />
        </div>
        <div className="notif-text">{message}</div>
        <FooterBlock className="">
          <Button
            onClick={() => this.handleClose()}
            className="button-black button-borderless"
            label="Continuer"
            icon="/assets/images/arrow-right-blue.png"
          />
        </FooterBlock>
      </Modal>
    )
  }
}

const MapStateToProps = ({ modal }) => ({
  isOpened: get(modal, 'isOpened', false),
  title: get(modal, 'title'),
  message: get(modal, 'message'),
  type: get(modal, 'type'),
  onClose: () => get(modal, 'onClose'),
})

const MapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeModal()),
})

export default connect(MapStateToProps, MapDispatchToProps)(NotifModal)
