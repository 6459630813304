import React from 'react'
import FooterBlock from '../footer-block'
import Button from '../action-button'
import Modal from '../modal'
import TitleBlock from '../title-block'
import HowTo from '../how-to'

export default ({ isOpened, onClose }) => {
  const slides = [
    {
      img: '/assets/images/howTo_4.png',
      text: 'Vous avez terminé, il vous suffit de ',
      bold: 'plier la trottinette',
      text2: 'comme indiqué.',
    },
    {
      img: '/assets/images/howTo_5.png',
      text: 'Replacez votre trottinette dans ',
      bold: 'une borne libre.',
      text2: '',
    },
    {
      img: '/assets/images/howTo_6.png',
      text: 'Attendez que le ',
      bold: 'voyant lumineux passe au vert',
      text2: ' pour confirmer votre restitution.',
    },
  ]

  return (
    <Modal isOpened={isOpened} onClose={() => onClose()}>
      <TitleBlock className="" title="Comment restituer une trottinette ?" emoji="" text="" />
      <HowTo slides={slides} />
      <FooterBlock className="footer-white">
        <Button
          onClick={() => onClose()}
          className="button-black"
          label="Ok, ça marche"
          icon="/assets/images/arrow-right-blue.png"
        />
      </FooterBlock>
    </Modal>
  )
}
