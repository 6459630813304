import { START_RENTAL, LOGOUT_SUCCEEDED } from '../constants'

const initialState = {
  scooterId: '',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case START_RENTAL:
      return {
        ...state,
        scooterId: action.scooterId,
      }
    case LOGOUT_SUCCEEDED:
      return initialState
    default:
      return state
  }
}
