import React from 'react'
import FooterBlock from '../footer-block'
import ActionButton from '../action-button'
import Redirect from '../conditional-redirect'
import Modal from '../modal'

export default class UnlockingConfirmation extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      validating: false,
      done: false,
      shouldRedirect: false,
    }
  }

  onValidate() {
    this.setState({ validating: true })
    const { onSubmit } = this.props
    onSubmit(() => {
        this.setState({ validating: false, done: true })
      },
      () => {
        this.setState({ validating: false, done: false })
    })
  }

  render() {
    const { isOpened, onClose, rackNumber } = this.props
    const { validating, done, shouldRedirect } = this.state

    return (
      <Modal className="no-scroll" isOpened={isOpened} onClose={() => onClose()}>
        <div className="unlocking-modal-content">
          <div className="rack">Rack</div>
          <div className="rack-number">
            n°
            {rackNumber}
          </div>
          <img className="scooter" src="/assets/images/scooter.png" />
        </div>
        <FooterBlock className="footer-white">
          {!(validating || done) && (
            <ActionButton
              onClick={() => this.onValidate()}
              className="button-black icon-reverse"
              label="Déverrouiller"
              icon="/assets/images/lock.png"
            />
          )}
          {validating && (
            <div className="lds-container">
              <div className="lds-ellipsis">
                <div />
                <div />
                <div />
                <div />
              </div>
            </div>
          )}
          {done && (
            <ActionButton
              onClick={() => this.setState({ shouldRedirect: true })}
              className="button-green icon-reverse"
              icon="/assets/images/done.png"
            />
          )}
          {!(validating || done) && (
            <ActionButton
              onClick={() => onClose()}
              className="black-border"
              label="Annuler"
              icon=""
            />
          )}
        </FooterBlock>
        <Redirect condition={shouldRedirect} to="/rental" />
      </Modal>
    )
  }
}
