import {
  LOGIN_SUCCEEDED,
  LOGIN_FAILED,
  LOGOUT_SUCCEEDED,
  REFRESH_SESSION_SUCCESS,
  REFRESH_SESSION_ERROR,
} from '../constants'

const initialState = {
  isLogged: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_FAILED:
      return {
        ...state,
        error: true,
      }
    case LOGIN_SUCCEEDED:
      return {
        ...state,
        ...action.payload,
        error: false,
        isLogged: true,
      }
    case LOGOUT_SUCCEEDED:
      return initialState
    case REFRESH_SESSION_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLogged: true,
      }
    case REFRESH_SESSION_ERROR:
      return {
        ...state,
        isLogged: false,
      }
    default:
      return state
  }
}
