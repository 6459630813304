import { LOGIN_USER, LOGOUT_USER, REFRESH_SESSION } from '../constants'

export const loginUser = payload => ({
  payload,
  type: LOGIN_USER,
})

export const logoutUser = () => ({
  type: LOGOUT_USER,
})

export const refreshSession = () => ({
  type: REFRESH_SESSION,
})
