import { OPEN_MODAL, CLOSE_MODAL } from '../constants'

const initialState = {
  isOpened: false,
  title: '',
  message: '',
  type: '',
  onClose: () => null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        ...state,
        isOpened: true,
        title: action.title,
        message: action.message,
        type: action.modalType,
        onClose: action.onClose,
      }
    case CLOSE_MODAL:
      return initialState
    default:
      return state
  }
}
