import api from './api'

export const lockRack = (_, success) => {
  success()
  // api.get(`/racks/${rackId}/lock`, (data)=>success(), ()=>failed());
}

export const unlockRack = () => null

export const getRacks = () => api.get2('/racks')

export default { getRacks }
