import { OPEN_MODAL, CLOSE_MODAL } from '../constants'

export const openModal = (title, message, modalType, onClose) => ({
  title,
  message,
  modalType,
  onClose,
  type: OPEN_MODAL,
})

export const closeModal = () => ({
  type: CLOSE_MODAL,
})
