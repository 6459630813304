import React from 'react'
import { connect } from 'react-redux'
import {
  ActionButton,
  NavHeader,
  FooterBlock,
  Input,
  TitleBlock,
  ConditionalRedirect,
  NavButton,
} from '../../components'
import { loginUser } from '../../actions'

class Login extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      password: '',
    }

    this.onChangeValue = this.onChangeValue.bind(this)
  }

  onChangeValue(key) {
    return e => {
      this.setState({ [key]: e.target.value })
    }
  }

  formSubmit(e) {
    const { login } = this.props
    const { email, password } = this.state

    e.preventDefault()

    login(email, password)
  }

  render() {
    const topInfo = {
      title: 'Bonjour 👋',
      emoji: '',
      text: 'Renseignez les champs ci-dessous pour utiliser les services Plume.',
    }
    const { error, isLogged } = this.props
    const { email, password } = this.state

    return (
      <form className="account-container" onSubmit={e => this.formSubmit(e)}>
        <div className="account-top">
          <NavHeader prev="/" pageTitle="Se connecter" help="" />
          <TitleBlock
            className="title-margin-top"
            title={topInfo.title}
            emoji={topInfo.emoji}
            text={topInfo.text}
          />
          <div className="account-form">
            <Input
              className={error ? 'error' : undefined}
              label="Identifiant"
              type="email"
              onChange={this.onChangeValue('email')}
            />
            <Input
              className={error ? 'error' : undefined}
              label="Mot de passe"
              type="password"
              onChange={this.onChangeValue('password')}
            />
          </div>
        </div>
        <FooterBlock className="footer-gray">
          <NavButton
            className="button-invisible"
            to="/forgot-password"
            label="Mot de passe oublié ? "
          />
          {email && password ? (
            <ActionButton
              className="button-black button-block"
              type="submit"
              label="Se connecter"
              icon="/assets/images/arrow-right-blue.png"
            />
          ) : (
            <ActionButton
              type="button"
              className="button-disabled button-block"
              onClick={() => null}
              label="Se connecter"
              icon="/assets/images/arrow-right-blue.png"
            />
          )}
        </FooterBlock>
        <ConditionalRedirect condition={isLogged} to="/" />
      </form>
    )
  }
}

const MapStateToProps = ({ user: { error, isLogged } }) => ({
  error,
  isLogged,
})

const MapDispatchToProps = dispatch => ({
  login: (email, password) => dispatch(loginUser({ email, password })),
})

export default connect(MapStateToProps, MapDispatchToProps)(Login)
