import React from 'react'
import { Redirect } from 'react-router-dom'
import queryString from 'query-string'
import { ActionButton, NavHeader, FooterBlock, Input, TitleBlock } from '../../components'
import { createPassword } from '../../api'

export default class AskAccount extends React.Component {
  constructor(props) {
    super(props)

    const {
      location: { search },
    } = props
    const params = queryString.parse(search)
    const { email, token, name } = params

    this.state = {
      token,
      email,
      name,
      password: '',
      passwordConfirm: '',
      error: '',
      success: false,
    }

    this.onChangeValue = this.onChangeValue.bind(this)
  }

  onChangeValue(key) {
    return e => {
      this.setState({ [key]: e.target.value, error: '' })
    }
  }

  formSubmit() {
    const { token, password, email, passwordConfirm } = this.state
    createPassword(
      {
        token,
        password,
        email,
        passwordConfirm,
      },
      () => this.setState({ success: true }),
      err => this.setState({ error: err }),
    )
  }

  render() {
    const { email, password, name, passwordConfirm, success, error } = this.state
    const topInfo = {
      title: `Bonjour ${name} ✌️`,
      emoji: '',
      text: 'Choisissez un identifiant et un mot de passe pour utiliser les services Plume.',
    }

    console.log('success ? ', success)
    if (success) {
      return <Redirect to="/how-to" />
    }

    return (
      <div className="account-container">
        <div className="account-top">
          <NavHeader prev="/" pageTitle="Se connecter" help="" />
          <TitleBlock
            className="title-margin-top"
            title={topInfo.title}
            emoji={topInfo.emoji}
            text={topInfo.text}
          />
          <div className="account-form">
            <Input
              value={email}
              disabled
              className=""
              label="Identifiant"
              type="text"
              onChange={this.onChangeValue('email')}
            />
            <Input
              value={password}
              className=""
              label="Mot de passe"
              type="password"
              onChange={this.onChangeValue('password')}
            />
            <Input
              value={passwordConfirm}
              className=""
              label="Confirmer Mot de passe"
              type="password"
              onChange={this.onChangeValue('passwordConfirm')}
            />
          </div>
          <div className="account-form">{error && `Une erreur est survenue : ${error}`}</div>
        </div>
        <FooterBlock className="footer-gray">
          {email && password && passwordConfirm && password === passwordConfirm ? (
            <ActionButton
              className="button-black"
              onClick={() => this.formSubmit()}
              label="Confirmer"
              icon="/assets/images/arrow-right-blue.png"
            />
          ) : (
            <ActionButton
              className="button-disabled"
              onClick={() => null}
              label="Confirmer"
              icon="/assets/images/arrow-right-blue.png"
            />
          )}
        </FooterBlock>
      </div>
    )
  }
}
