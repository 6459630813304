import React from 'react'

import { Link } from 'react-router-dom'

export default ({ className, to, label, icon }) => (
  <>
    <Link to={to} className={`button-block ${className}`}>
      {label}
      {icon && <img src={icon} alt={label} />}
    </Link>
  </>
)
