import React from 'react'

import './styles.scss'

export default ({ className, label, ...restProps }) => (
  <div className={`custom-input ${className}`}>
    <div className="input-label">{label}</div>
    <input {...restProps} />
  </div>
)
