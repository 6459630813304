import createSagaMiddleware from 'redux-saga'
import { all } from 'redux-saga/effects'

import authSagas from './auth'

const sagaMiddleware = createSagaMiddleware()

function* rootSaga() {
  yield all([authSagas()])
}

export const initSagas = middleware => middleware.run(rootSaga)

export default sagaMiddleware
