import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import {
  HomePage,
  Login,
  AskAccount,
  CreateAccount,
  Scan,
  Rental,
  HowTo,
  Logout,
  ForgotPassword,
  ResetPassword,
} from '../../scenes'
import { refreshSession } from '../../actions'

let timeout

const AppRouter = ({ isLogged, pollSession }) => {
  useEffect(() => {
    if (timeout) {
      clearInterval(timeout)
    }

    if (isLogged) {
      pollSession()
      timeout = setInterval(pollSession, 10000)
    }
  }, [isLogged])

  return (
    <Router>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route path="/login" component={Login} />
        <Route path="/logout" component={Logout} />
        <Route path="/ask-account" component={AskAccount} />
        <Route path="/how-to" component={HowTo} />
        <Route path="/create-account" component={CreateAccount} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/reset-password" component={ResetPassword} />
        <Route path="/scan" component={Scan} />
        <Route path="/rental" component={Rental} />
      </Switch>
    </Router>
  )
}

const mapStateToProps = ({ user: { isLogged } }) => ({
  isLogged,
})

const mapDispatchToProps = dispatch => ({
  pollSession: () => dispatch(refreshSession()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter)
