import { REFRESH_RACKS_SUCCESS, LOGOUT_SUCCEEDED } from '../constants'

const initialState = {
  racks: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case REFRESH_RACKS_SUCCESS:
      return {
        ...state,
        racks: action.racks,
      }
    case LOGOUT_SUCCEEDED:
      return initialState
    default:
      return state
  }
}
