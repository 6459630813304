export const LOGIN_USER = 'LOGIN_USER'
export const LOGIN_SUCCEEDED = 'LOGIN_SUCCEEDED'
export const LOGIN_FAILED = 'LOGIN_FAILED'

export const LOGOUT_USER = 'LOGOUT_USER'
export const LOGOUT_SUCCEEDED = 'LOGOUT_USER_SUCCEEDED'

export const START_RENTAL = 'START_RENTAL'

export const REFRESH_SESSION = 'REFRESH_SESSION'
export const REFRESH_SESSION_SUCCESS = 'REFRESH_SESSION_SUCCESS'
export const REFRESH_SESSION_ERROR = 'REFRESH_SESSION_ERROR'

export const REFRESH_RACKS_SUCCESS = 'REFRESH_RACKS_SUCCESS'
export const REFRESH_RACKS_ERROR = 'REFRESH_RACKS_ERROR'

export const OPEN_MODAL = 'OPEN_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'
