import React from 'react'

export default ({ className, onClick, label, icon, type }) => (
  <button
    className={`button-block ${className}`}
    onClick={onClick}
    type={type === 'submit' ? 'submit' : 'button'}
  >
    <span>{label}</span>
    {icon && <img src={icon} alt={label} />}
  </button>
)
