import React from 'react'
import FooterBlock from '../footer-block'
import Button from '../action-button'
import Modal from '../modal'
import TitleBlock from '../title-block'
import get from 'lodash'

import { logout as apiLogout } from '../../api'
import { logoutUser } from '../../actions'

import './styles.scss'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'

class Menu extends React.Component {
  render() {
    const { isOpened, onClose, userName, logoutAction, isRenting } = this.props

    return (
      <Modal className="menu-container" isOpened={isOpened} onClose={() => onClose()}>
        <div className="menu-top">
          <h2>Menu</h2>
          <img src="/assets/images/cross.png" onClick={() => onClose()} />
          <TitleBlock
            className="menu-title"
            title={`Bonjour ${userName ? userName : ''} ✌️`}
            emoji=""
            text=""
          />
          <div className="menu-links">
            {!isRenting && (
              <>
                {this.renderLink('/scan', 'Scanner un QR Code')}
                {this.renderLink('/how-to', 'Tutoriel')}
              </>
            )}
          </div>
        </div>

        <FooterBlock className="footer-white menu-footer">
          <Button
            onClick={() => {
              apiLogout(logoutAction)
              onClose()
            }}
            className="button-red"
            label="Déconnexion"
          />
          <img className="menu-logo" src="/assets/images/logo-mini.png" />
          <div className="menu-version">V 1.0.0</div>
        </FooterBlock>
      </Modal>
    )
  }

  renderLink(url, label) {
    const { pathname } = this.props.location

    if (pathname === url) {
      return (
        <div className="menu-link">
          <div className="menu-link-current" />
          {label}
        </div>
      )
    }

    return (
      <Link className="menu-link" to={url}>
        {label}
      </Link>
    )
  }
}

const MapStateToProps = ({ user }) => ({
  userName: user.firstName,
})

const MapDispatchToProps = dispatch => ({
  logoutAction: () => dispatch(logoutUser()),
})

export default withRouter(connect(MapStateToProps, MapDispatchToProps)(Menu))
